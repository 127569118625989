import bebes from "./bebes.png";
import testes from "./testes.jpg";
import mes1 from "./mes1.png";

const images = {
    bebes,
    testes,
    mes1
};

export default images;
